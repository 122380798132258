<template>
	<div class="main-content">
		<div class="row mt-4">
			<div class="col-12">
				<trip-history :waitlistCount="0" :isUpcoming="true" trip-type="upcoming" :request-function="(params) => fetchTrips(params)" :show-trip-date="true">
					<template slot="table-actions" slot-scope="props">
						<b-dropdown size="sm" variant="link" class="position-relative text-center w-100" :toggle-class="'text-decoration-none'" :no-caret="true">
							<template #button-content>
								<div class="w-100 text-center">
									<i class="fe fe-more-vertical"></i>
								</div>
							</template>
							<template v-if="!props.data.item.start_trip">
								<b-dropdown-item v-if="!userHasPermission('trips004') && !userHasPermission('trips006')">
									UnAuthorised
								</b-dropdown-item>
								<b-dropdown-item @click.prevent.stop="props.startTrip()" v-if="userHasPermission('trips004')">
									Start Trip
								</b-dropdown-item>
								<b-dropdown-item v-if="userHasPermission('trips006')" link-class="text-danger" @click.prevent.stop="
									props.openDelete('Please note this trip is yet to start!')
									">
									Cancel Trip
								</b-dropdown-item>
								<!-- <b-dropdown-item v-if="userHasPermission('trips006')"  @click.prevent.stop="showChangeDriver = true">
               Update Driver
              </b-dropdown-item>
              <b-dropdown-item v-if="userHasPermission('trips006')"  @click.prevent.stop="showChangeVehicle = true">
             Update Vehicle
            </b-dropdown-item> -->
								<b-dropdown-item v-if="dayIsInThePast(props.data.item.trip_date) && userHasPermission('trips010')" @click.prevent.stop="$event => props.completeThisTrip(props.data.item.id)
									">
									Complete Trip
								</b-dropdown-item>
							</template>
						</b-dropdown>
					</template>
				</trip-history>
			</div>
		</div>
		<!-- <template v-if="$route.name === 'UpcomingTrips'">
			<change-trip-modal
				:show="showChangeDriver"
				:model="trip"
				:drivers="drivers"
				:vehicles="vehicles?.data || []"
				update-type="driver"
				:selectedTrip="selectedTrip"
				modal-title="Update Driver"
				@close="showChangeDriver = false"
				@data-changed="processDataChange"
				></change-trip-modal>

			<change-trip-modal
				:show="showChangeVehicle"
				:model="trip"
				:drivers="drivers"
				:vehicles="vehicles?.data || []"
				update-type="vehicle"
				:selectedTrip="selectedTrip"
				modal-title="Update Vehicle"
				@close="showChangeVehicle = false"
				@data-changed="processDataChange"
				></change-trip-modal>
		</template> -->
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
// import ChangeTripModal from '@/components/modals/ChangeTripDriverModal'
import TripHistory from '@/components/modules/trips/TripHistory'
import moment from 'moment'
export default {
	components: {
		TripHistory,
		// ChangeTripModal,
	},
	data()
	{
		return {
			tripsData: '',
			trip: null,
			// showChangeDriver: false,
			// showChangeVehicle: false,
			// drivers: [],
			// vehicles: [],
			// selectedTrip: 'upcoming'
		}
	},
	methods: {
		dayIsInThePast(trip_date)
		{
			const today = moment()
			const formatted = moment(trip_date)

			const differenceInDays = formatted.diff(today, 'days')
			return differenceInDays < 0
		},
		async fetchTrips(params)
		{
			// this.params = params
			const limit = params ? params.limit : 10
			const page = params ? params.page : 1
			// const from = params ? params.from : null

			const baseUrl = '/v1/trips/upcoming'
			let url = `${baseUrl}?limit=${limit}&page=${page}&metadata=true&sort[created_at]=desc&`
			if (params?.from || params?.to) {
				if (params?.from) {
					url += `from=${params.from}&`
				}
				if (params?.to) {
					url += `to=${params.to}`
				}
			}
			if (params?.search) {
				url += `&search=${params.search}`
			}
			const result = await this.axios.get(url)
			const data = result.data.data
			this.tripsData = data
			return {
				data: data,
				meta_data: result.data?.metadata,
			}
		},
		// fetchDrivers () {
		//   this.axios.get('/v1/drivers?status=active').then((res) => {
		//     this.drivers = res.data
		//   })
		// },
		// fetchVehicles () {
		//   this.axios.get('/v1/vehicles?status=active').then((res) => {
		//     this.vehicles = res.data.data
		//   })
		// },
		processDataChange({ vehicle, driver })
		{
			if (vehicle) {
				this.trip.vehicle = vehicle
			}
			if (driver) {
				this.trip.driver = driver
			}
		}
	},
	mounted()
	{
		this.fetchTrips()
		// this.fetchDrivers()
		// this.fetchVehicles()
	}
}
</script>
